import { axiosWithAuth } from "../helpers/AxiosInterceptor"
import {
  BASE_URL,
  COPY_TO_CLIPBOARD_TYPES,
  ITEM_TYPES,
  RTMP_SERVER_URL
} from "../helpers/Constants"
import {
  boolToYesNo,
  InfoBubble,
  getUserEmail,
  triggerTabError,
  userNotLimited
} from "../helpers/Helpers"
import { CopyToClipboardButton } from "./CopyToClipboardButton"
import { UpdateItemTitleModal } from "./UpdateItemTitleModal"
import Clappr from "clappr"
import { useState } from "react"
import { Accordion, Button, Form, Header, Input, Label, Modal, Segment } from "semantic-ui-react"

export function StreamDisplay({
  siteSlug,
  stream,
  toggleStreamCallback,
  updateStreamTitleCallback
}) {
  const [toggleInProgress, setToggleInProgress] = useState(false)
  const [errorState, setErrorState] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showTestModeModal, setShowTestModeModal] = useState(false)

  const generateRTMPStreamKey = (stream) =>
    `stream${stream.streamid}?user=streampublisher&pass=${stream.key}`

  const streamDirectLink = `${BASE_URL}churches/${siteSlug}?stream=${stream.streamname
    .split(" ")
    .join("")
    .toLowerCase()}`

  function toggleStream() {
    console.log("Entered toggleStream()")

    setToggleInProgress(true)
    setErrorState(null)
    const url = `${BASE_URL}admin/api/toggle-stream`
    axiosWithAuth
      .post(url, {
        validateSiteForType: true,
        user: getUserEmail(),
        type: "stream",
        streamID: stream.streamid,
        toggleType: stream.streamisalive ? 2 : 1
      })
      .then(() => {
        setToggleInProgress(false)
        toggleStreamCallback(stream.streamid)
      })
      .catch(() => {
        setToggleInProgress(false)
        triggerTabError(setErrorState)
      })
  }

  function initializePlayer() {
    setTimeout(
      () =>
        new Clappr.Player({
          source: stream.streamurl,
          parentId: `#player${stream.streamid}`,
          preload: "none",
          width: "100%",
          height: "100%",
          autoPlay: true
        }),
      10
    )
  }

  const buildStopStreamModal = () => (
    <Modal
      showModal={showModal}
      trigger={
        <Button
          style={{ marginTop: "15px" }}
          disabled={toggleInProgress}
          loading={toggleInProgress}
          color="red">
          Stop Stream
        </Button>
      }
      onClose={() => setShowModal(false)}
      onOpen={() => setShowModal(true)}
      open={showModal}
      style={{ width: "500px" }}>
      <Header icon="warning" content={"Stop stream"} />
      <Modal.Content>
        <div>
          <p>
            Are you sure you want to stop the stream? This may cause ongoing recordings to be
            corrupted and upcoming streams to be skipped.
          </p>
          <p>Click "Confirm" below to process, or "Close" to cancel.</p>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setShowModal(false)}>Close</Button>
        <Button
          className="blue"
          onClick={() => {
            setShowModal(false)
            toggleStream()
          }}>
          Confirm
        </Button>
      </Modal.Actions>
    </Modal>
  )

  const buildTestModeModal = () => (
    <Modal
      showModal={showTestModeModal}
      trigger={
        <Button style={{ marginTop: "15px" }} color="blue">
          View Stream (Test Mode)
        </Button>
      }
      onClose={() => setShowTestModeModal(false)}
      onOpen={() => setShowTestModeModal(true)}
      open={showTestModeModal}
      style={{ width: "842px" }}>
      <Header icon="video" content={"View stream in test mode"} />
      <Modal.Content>
        {initializePlayer()}
        <div id={`player${stream.streamid}`} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setShowTestModeModal(false)}>Close</Button>
      </Modal.Actions>
    </Modal>
  )

  const buildLinksArea = () => (
    <div style={{ marginTop: "35px" }}>
      <Form.Field>
        <Label>
          Stream Direct Link&nbsp;
          <InfoBubble
            message={
              "Link leading directly to the specific stream (if visible) on your parish's public MassLivestream page."
            }
          />
        </Label>
        <Input
          style={{ width: "485px", maxWidth: "100%" }}
          value={streamDirectLink}
          readOnly={true}
        />
        <CopyToClipboardButton type={COPY_TO_CLIPBOARD_TYPES.ICON} textToCopy={streamDirectLink} />
      </Form.Field>
      <Form.Field style={{ marginTop: "15px" }}>
        <Label>
          Stream Status Embed Code (Single Stream)&nbsp;
          <InfoBubble
            message={
              "Customizable HTML embed code that defaults to show the current image for the stream, the title of the  ongoing/next event for the stream, and a direct link to the stream."
            }
          />
        </Label>
        <Input
          style={{ width: "485px", maxWidth: "100%" }}
          value={stream.streamstatusembedcode}
          readOnly={true}
        />
        <CopyToClipboardButton
          type={COPY_TO_CLIPBOARD_TYPES.ICON}
          textToCopy={stream.streamstatusembedcode}
        />
      </Form.Field>
    </div>
  )

  const buildDetailsArea = () => (
    <>
      <Accordion
        panels={[
          {
            key: "details",
            title: "Additional Information",
            content: {
              content: (
                <div className="additionalInfoBox">
                  <p>
                    <i>
                      To make changes to fields under "Additional Information", please&nbsp;
                      <a href={"mailto:support@masslivestream.com"}>contact us</a>.
                    </i>
                  </p>
                  {stream.streamtype === 1 && !stream.isprivate && (
                    <p>
                      <b>Camera Reachable</b>: {boolToYesNo(stream.cameraisalive)}&nbsp;
                      <InfoBubble
                        size={"small"}
                        message={
                          <div>
                            <p>
                              Whether or not the camera for the stream is reachable by the
                              MassLivestream backend. If unreachable, the camera is likely offline.
                            </p>
                            <p>
                              When a camera is unreachable, the corresponding stream will not be
                              able to go live.
                            </p>
                          </div>
                        }
                      />
                    </p>
                  )}
                  {!stream.isprivate && (
                    <p>
                      <b>Site Order</b>: {stream.siteorder}&nbsp;
                      <InfoBubble
                        size={"small"}
                        message={
                          <div>
                            <p>
                              The order in which the stream will appear on your parish's public
                              MassLivestream page.
                            </p>
                            <p>Only relevant if your parish has more than one stream.</p>
                          </div>
                        }
                      />
                    </p>
                  )}
                  <p>
                    <b>Private Stream</b>: {boolToYesNo(stream.isprivate)}&nbsp;
                    <InfoBubble
                      size={"small"}
                      message={
                        <div>
                          <p>Whether or not the stream is private.</p>
                          <p>
                            Private streams are not included on your parish's public MassLivestream
                            page and are only available through permalink.
                          </p>
                        </div>
                      }
                    />
                  </p>
                  {stream.streamtype === 1 && (
                    <p>
                      <b>Stream on Schedule</b>: {boolToYesNo(stream.streamonschedule)}&nbsp;
                      <InfoBubble
                        size={"small"}
                        message={
                          <div>
                            <p>Whether or not the stream is only live for scheduled events.</p>
                            <p>
                              If "No", the stream will be live on your parish's public
                              MassLivestream page 24/7.
                            </p>
                          </div>
                        }
                      />
                    </p>
                  )}
                  <p>
                    <b>Hide When Offline </b>: {boolToYesNo(stream.hidewhenoffline)}&nbsp;
                    <InfoBubble
                      size={"small"}
                      message={
                        "Whether or not the stream should be hidden from your parish's public MassLivestream page when offline."
                      }
                    />
                  </p>
                </div>
              )
            }
          }
        ]}
      />
      {stream.streamtype === 2 && (
        <Accordion
          panels={[
            {
              key: "details",
              title: "RTMP Information",
              content: {
                content: (
                  <>
                    For details on connecting using an RTMP stream, see{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={
                        "https://docs.google.com/document/d/1mJ10BNFNp5vdAYLXwJqE1AlZEYlifqORLGp_M2QXldU/edit?usp=sharing"
                      }>
                      here
                    </a>
                    .
                    <Form.Field style={{ textAlign: "left" }}>
                      <Label>
                        Server&nbsp;
                        <InfoBubble
                          message={
                            "Server URL to specify when streaming from RTMP livestreaming software such as OBS or Streamlabs."
                          }
                        />
                      </Label>
                      <Input
                        style={{ width: "485px", maxWidth: "100%" }}
                        value={RTMP_SERVER_URL}
                        readOnly={true}
                      />
                      <CopyToClipboardButton
                        type={COPY_TO_CLIPBOARD_TYPES.ICON}
                        textToCopy={RTMP_SERVER_URL}
                      />
                    </Form.Field>
                    <Form.Field style={{ textAlign: "left", marginTop: "15px" }}>
                      <Label>
                        Stream Key&nbsp;
                        <InfoBubble
                          message={
                            "Server key to specify when streaming from RTMP livestreaming software such as OBS or Streamlabs."
                          }
                        />
                      </Label>
                      <Input
                        style={{ width: "485px", maxWidth: "100%" }}
                        value={generateRTMPStreamKey(stream)}
                        readOnly={true}
                      />
                      <CopyToClipboardButton
                        type={COPY_TO_CLIPBOARD_TYPES.ICON}
                        textToCopy={generateRTMPStreamKey(stream)}
                      />
                    </Form.Field>
                  </>
                )
              }
            }
          ]}
        />
      )}
    </>
  )

  return (
    <div className="center-div" style={{ marginBottom: "10px", maxWidth: "100%" }}>
      <Segment style={{ width: "550px", maxWidth: "100%" }}>
        <div style={{ textAlign: "center" }}>
          <div style={{ float: "right" }}>
            <UpdateItemTitleModal
              type={ITEM_TYPES.STREAM}
              id={stream.streamid}
              currentTitle={stream.streamname}
              callback={updateStreamTitleCallback}
            />
          </div>
          {(stream.streamisalive || stream.streamonscheduleimg !== "NA") && (
            <img
              style={{ marginLeft: "20px" }}
              class="rounded"
              width="250px"
              src={stream.streamisalive ? stream.livephotourl : stream.streamonscheduleimg}
              alt=""
            />
          )}
          <Header as="h2" textAlign="center">
            {stream.streamname}
          </Header>
          {stream.streamisalive ? (
            <b className="greenFont" style={{ fontSize: "16px" }}>
              Stream is Online
            </b>
          ) : (
            <b className="redFont" style={{ fontSize: "16px" }}>
              Stream is Offline
            </b>
          )}
          {buildDetailsArea()}
          {userNotLimited() &&
            (stream.streamisalive ? (
              stream.streamtype === 1 ? (
                <div>
                  {stream.isprivate ? (
                    buildTestModeModal()
                  ) : (
                    <a href={streamDirectLink} target="_blank" rel="noreferrer">
                      <Button style={{ marginTop: "15px" }}>View Stream</Button>
                    </a>
                  )}
                  <br />
                  {buildStopStreamModal()}
                </div>
              ) : (
                <div style={{ marginTop: "15px" }}>
                  <p>
                    <i>
                      RTMP streams stop within 5 minutes of the user ending the stream on their
                      device
                    </i>
                  </p>
                </div>
              )
            ) : stream.streamtype === 1 ? (
              <div>
                <Button
                  style={{ marginTop: "15px" }}
                  className="blue"
                  disabled={toggleInProgress}
                  loading={toggleInProgress}
                  onClick={toggleStream}>
                  Start Stream
                </Button>
                {stream.isprivate ? (
                  <div style={{ marginTop: "15px" }}>
                    <p>
                      <i>
                        Stream is private, starting it manually will allow for test mode viewing
                        only.
                        <br />
                        Schedule an event to start a sharable stream.
                      </i>
                    </p>
                  </div>
                ) : null}
              </div>
            ) : (
              <div style={{ marginTop: "15px" }}>
                <p>
                  <i>
                    RTMP streams start within 5 minutes of the user beginning the stream on their
                    device
                  </i>
                </p>
              </div>
            ))}
          {errorState && (
            <div style={{ marginTop: "15px" }} className="error">
              An error occurred, please try again.
            </div>
          )}
        </div>
        {!stream.isprivate ? buildLinksArea() : null}
      </Segment>
    </div>
  )
}
