import { CustomTable } from "../../components/CustomTable"
import { LoadingError } from "../../components/LoadingError"
import { ReloadDataButton } from "../../components/ReloadDataButton"
import { buildRecordingsTableColumns } from "../../configs/RecordingsTableConfig"
import { axiosWithAuth } from "../../helpers/AxiosInterceptor"
import { BASE_URL } from "../../helpers/Constants"
import { getUserEmail, logEvent, triggerTabError } from "../../helpers/Helpers"
import React, { useState, useEffect } from "react"
import { Header, Tab, Loader } from "semantic-ui-react"

export default function RecordingsTab({ siteInfo }) {
  const [recordings, setRecordings] = useState(null)
  const [recordingsLoaded, setRecordingsLoaded] = useState(false)
  const [recordingsLoadError, setRecordingsLoadError] = useState(false)
  const [errorState, setErrorState] = useState(false)

  useEffect(
    () => logEvent("Dashboard_Tab_Accessed", { email: getUserEmail(), tab: "recordings" }),
    []
  )
  // eslint-disable-next-line
  useEffect(() => loadRecordings(), [])

  function loadRecordings() {
    console.log("Entering loadRecordings()")
    setRecordingsLoaded(false)
    const url = `${BASE_URL}admin/api/site/${siteInfo.siteid}/recordings`
    axiosWithAuth
      .get(url)
      .then((response) => {
        setRecordings(response.data)
        setRecordingsLoaded(true)
      })
      .catch(() => {
        setRecordingsLoaded(true)
        setRecordingsLoadError(true)
      })
  }

  function updateTitleCallback(response) {
    if (response.success) {
      setRecordings(
        recordings.map((recording) =>
          recording.scheduleid === response.id
            ? { ...recording, schedtitle: response.newTitle }
            : recording
        )
      )
    }
  }

  function togglePublicCallback(response) {
    if (response.success) {
      setRecordings(
        recordings.map((recording) =>
          recording.scheduleid === response.id
            ? { ...recording, recordingispublic: !recording.recordingispublic }
            : recording
        )
      )
    } else {
      triggerTabError(setErrorState)
    }
  }

  function toggleSavedCallback(response) {
    if (response.success) {
      setRecordings(
        recordings.map((recording) =>
          recording.scheduleid === response.id
            ? { ...recording, keeprecording: !recording.keeprecording }
            : recording
        )
      )
    } else {
      triggerTabError(setErrorState)
    }
  }

  function deleteItemCallback(response) {
    if (response.success) {
      setRecordings(recordings.filter((recording) => recording.scheduleid !== response.id))
    }
  }

  return (
    <Tab.Pane attached={false}>
      <ReloadDataButton callback={loadRecordings} />
      <Header as="h1" textAlign={"center"}>
        Recordings
      </Header>
      <div className="hrLargeBlue" />
      {errorState && <div className="error">An error occurred, please try again.</div>}
      {recordingsLoadError ? (
        <LoadingError />
      ) : !recordingsLoaded ? (
        <Loader active inline="centered" />
      ) : recordings && recordings.length === 0 ? (
        <Header as="h3" textAlign="center" style={{ marginTop: "20px" }}>
          No recordings found.
        </Header>
      ) : (
        recordings && (
          <CustomTable
            tableColumns={buildRecordingsTableColumns(
              siteInfo.siteid,
              siteInfo.slug,
              updateTitleCallback,
              togglePublicCallback,
              toggleSavedCallback,
              deleteItemCallback
            )}
            tableData={recordings}
          />
        )
      )}
    </Tab.Pane>
  )
}
