import { axiosWithAuth } from "../helpers/AxiosInterceptor"
import { BASE_URL } from "../helpers/Constants"
import { getUserEmail, InfoBubble, logEvent } from "../helpers/Helpers"
import { LoadingError } from "./LoadingError"
import { useEffect, useState } from "react"
import { DateInput, TimeInput } from "semantic-ui-calendar-react"
import {
  Button,
  Header,
  Loader,
  Modal,
  Input,
  Label,
  Form,
  Checkbox,
  Table,
  Radio,
  Dropdown,
  Grid,
  Dimmer
} from "semantic-ui-react"

export const SINGLE_TYPE = "single"
export const SET_TYPE = "set"
export const RECURRING_TYPE = "recurring"

const STREAM_ATTRIBUTE = "stream"
const RECORD_ATTRIBUTE = "record"
const RECORDING_PRIVATE_ATTRIBUTE = "recordingPrivate"
const DAYS_OF_WEEK = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"]
const MINUTE_IN_MILLI = 60 * 1000
const FOUR_HOURS_IN_MILLI = 4 * 60 * MINUTE_IN_MILLI
const THREE_YEARS_IN_MILLI = 4 * 364 * 24 * 60 * MINUTE_IN_MILLI
const OCCURRENCE_OPTIONS = [
  {
    key: 0,
    text: "Every Occurrence",
    value: 0
  },
  {
    key: 1,
    text: "First occurrence of the month",
    value: 1
  },
  {
    key: 2,
    text: "Second occurrence of the month",
    value: 2
  },
  {
    key: 3,
    text: "Third occurrence of the month",
    value: 3
  },
  {
    key: 4,
    text: "Fourth occurrence of the month",
    value: 4
  },
  {
    key: 5,
    text: "Fifth occurrence of the month",
    value: 5
  }
]

export function ScheduleEventModal({
  siteInfo,
  editType,
  editID,
  setStatusCallback,
  reloadScheduleCallback
}) {
  const [showModal, setShowModal] = useState(false)
  const [scheduleInProgress, setScheduleInProgress] = useState(false)
  const [scheduleResult, setScheduleResult] = useState(null)
  const [schedulingInfo, setSchedulingInfo] = useState(false)
  const [schedulingInfoLoaded, setSchedulingInfoLoaded] = useState(false)
  const [schedulingInfoLoadError, setSchedulingInfoLoadError] = useState(false)

  const [title, setTitle] = useState(null)
  const [titleError, setTitleError] = useState(null)
  const [makePrivate, setMakePrivate] = useState(false)
  const [streams, setStreams] = useState(null)
  const [streamsError, setStreamsError] = useState(null)
  const [type, setType] = useState(SINGLE_TYPE)
  const [daysToUse, setDaysToUse] = useState([])
  const [daysToUseError, setDaysToUseError] = useState(null)
  const [occurrenceOfMonth, setOccurrenceOfMonth] = useState(0)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [dateAndTimeError, setDateAndTimeError] = useState(null)

  const [hasPrivateStreamScheduled, setHasPrivateStreamScheduled] = useState(false)

  useEffect(
    () =>
      showModal &&
      logEvent("Dashboard_Modal_Accessed", {
        email: getUserEmail(),
        modal: "event"
      }),
    [showModal]
  )
  // eslint-disable-next-line
  useEffect(() => loadSchedulingInfo(), [showModal])

  const isRecurring = () => type === RECURRING_TYPE

  const getDateLabel = () => (type === SINGLE_TYPE ? "Event" : "Series")

  function loadSchedulingInfo() {
    if (showModal) {
      console.log("Entering loadSchedulingInfo()")

      setSchedulingInfoLoaded(false)
      setSchedulingInfoLoadError(false)
      const url = `${BASE_URL}admin/api/schedule-event-info`
      axiosWithAuth
        .post(url, {
          siteID: siteInfo.siteid,
          editType,
          editID
        })
        .then((response) => {
          setInitialState(response.data)
          setSchedulingInfoLoaded(true)
        })
        .catch((e) => {
          setSchedulingInfoLoaded(true)
          setSchedulingInfoLoadError(true)
        })
    }
  }

  function createEvent() {
    console.log(`Entered createEvent()`)

    const truthyToNumber = (thing) => (thing ? 1 : 0)
    const formatHours = (hour, type) =>
      type === "pm" && parseInt(hour) <= 11
        ? (parseInt(hour) + 12).toString()
        : type === "am" && hour === "12"
        ? "00"
        : hour

    if (validateInputs()) {
      setScheduleResult(null)
      setScheduleInProgress(true)

      const url = `${BASE_URL}admin/api/schedule-event`
      const startTimeParts = startTime.replace(":", ",").replace(" ", ",").split(",")
      const endTimeParts = endTime.replace(":", ",").replace(" ", ",").split(",")
      const payload = {
        user: getUserEmail(),
        siteID: siteInfo.siteid,
        type: schedulingInfo.editType || type,
        editID,
        title,
        private: truthyToNumber(makePrivate),
        streams: JSON.stringify(
          streams
            .filter((stream) => stream.stream)
            .map((stream) => ({
              streamID: stream.streamID,
              streamName: stream.streamName,
              streamOnSchedule: truthyToNumber(stream.streamOnSchedule),
              record: truthyToNumber(stream.record),
              recordingPrivate: truthyToNumber(stream.recordingPrivate)
            }))
        ),
        daysToUse: JSON.stringify(daysToUse),
        occurrenceOfMonth,
        startDate,
        startHour: formatHours(startTimeParts[0], startTimeParts[2]),
        startMinute: startTimeParts[1],
        endDate,
        endHour: formatHours(endTimeParts[0], endTimeParts[2]),
        endMinute: endTimeParts[1]
      }
      axiosWithAuth
        .post(url, payload)
        .then((response) => {
          setScheduleResult(
            <span className={response.data.success ? "greenFont" : "redFont"}>
              {response.data.infoString}
            </span>
          )
          setScheduleInProgress(false)
          if (response.data.success) {
            setStatusCallback(response.data)
            resetAndClose(true)
          }
        })
        .catch(() => {
          setScheduleResult(<span className="error">An error occurred, please try again</span>)
          setScheduleInProgress(false)
        })
    }
  }

  function setInitialState(data) {
    console.log("Entering setInitialState()")

    const formatTime = (hours, minutes) =>
      `${(hours > 0 && hours < 10) || (hours > 12 && hours < 22) ? "0" : ""}${
        hours === 0 ? 12 : hours > 12 ? hours - 12 : hours
      }:${minutes} ${hours < 12 ? "am" : "pm"}`

    setSchedulingInfo(data)

    const streams = data.streams.map((stream) => ({
      allowRecordings: stream.allowrecordings,
      record: false,
      recordingPrivate: !!stream.isprivate,
      stream: data.streams.length === 1,
      streamID: stream.streamid,
      streamName: stream.streamname,
      streamOnSchedule: stream.streamonschedule,
      streamIsPrivate: stream.isprivate
    }))
    setStreams(streams)

    if (data.editInfo) {
      setTitle(data.editInfo.title || null)
      setMakePrivate(!!data.editInfo.private || null)
      setStreams(
        streams.map((stream) => ({
          ...stream,
          ...data.editInfo.streams.find((innerStream) => stream.streamID === innerStream.streamID)
        }))
      )
      setType((!editType || editType) === SET_TYPE ? SINGLE_TYPE : editType)
      setDaysToUse(data.editInfo.daysToUse || null)
      setOccurrenceOfMonth(data.editInfo.occurrenceOfMonth || 0)
      setStartDate(data.editInfo.startDate.replace(/\//g, "-"))
      setStartTime(formatTime(data.editInfo.startHour, data.editInfo.startMinute))
      setEndDate(data.editInfo.endDate.replace(/\//g, "-"))
      setEndTime(formatTime(data.editInfo.endHour, data.editInfo.endMinute))
      setHasPrivateStreamScheduled(
        streams.find((stream) => stream.streamIsPrivate === 1 && stream.stream) && isRecurring()
      )
    }
  }

  function validateInputs() {
    console.log(`Entered validateInputs()`)

    const getErrorMessage = (errorText) =>
      errorText ? <span className="error">{errorText}</span> : null

    const titleErrorText =
      title && title.length > 3 ? null : "Event title must be at least four characters"
    setTitleError(getErrorMessage(titleErrorText))
    const streamsErrorText = streams.find((stream) => stream.stream)
      ? null
      : "At least one stream must be selected"
    setStreamsError(getErrorMessage(streamsErrorText))
    const daysToUserErrorText =
      type === RECURRING_TYPE && daysToUse.length === 0 ? "At least one day must be selected" : null
    setDaysToUseError(getErrorMessage(daysToUserErrorText))

    const nowMilli = new Date().getTime()
    const startDTMilli = new Date(`${startDate} ${startTime}`).getTime()
    const startDateWithEndTimeMilli = new Date(`${startDate} ${endTime}`).getTime()
    const endDTMilli = new Date(`${endDate} ${endTime}`).getTime()
    const isRecording = streams.some((stream) => stream.record)
    let dtErrorText = null
    dtErrorText =
      !startDate || !startTime || !endDate || !endTime
        ? "Start and end date/time values must all be selected"
        : dtErrorText
    dtErrorText =
      !dtErrorText && startDTMilli <= nowMilli + MINUTE_IN_MILLI
        ? "Start date/time must be at least one minute in the future"
        : dtErrorText
    dtErrorText =
      !dtErrorText && endDTMilli <= startDTMilli
        ? "Start date/time must come before end date/time"
        : dtErrorText
    dtErrorText =
      !dtErrorText && type === RECURRING_TYPE && startDateWithEndTimeMilli <= startDTMilli
        ? "Start time must come before end time"
        : dtErrorText
    dtErrorText =
      !dtErrorText &&
      isRecording &&
      type === SINGLE_TYPE &&
      endDTMilli - startDTMilli > FOUR_HOURS_IN_MILLI
        ? "Recorded event cannot be longer than four hours"
        : dtErrorText
    dtErrorText =
      !dtErrorText &&
      isRecording &&
      type === RECURRING_TYPE &&
      startDateWithEndTimeMilli - startDTMilli > FOUR_HOURS_IN_MILLI
        ? "Recorded event be longer than four hours"
        : dtErrorText
    dtErrorText =
      !dtErrorText && endDTMilli > nowMilli + THREE_YEARS_IN_MILLI
        ? "Schedule cannot contain dates more than 3 years in the future"
        : dtErrorText
    setDateAndTimeError(getErrorMessage(dtErrorText))

    const errors = [titleErrorText, streamsErrorText, daysToUserErrorText, dtErrorText]
    return errors.every((error) => !error)
  }

  function resetAndClose(callReloadScheduleCallback) {
    setScheduleInProgress(false)
    setScheduleResult(null)
    setSchedulingInfoLoaded(false)
    setSchedulingInfoLoadError(false)

    setTitle(null)
    setTitleError(null)
    setMakePrivate(false)
    setStreams(null)
    setStreamsError(null)
    setType(SINGLE_TYPE)
    setDaysToUse([])
    setDaysToUseError(null)
    setOccurrenceOfMonth(0)
    setStartDate(null)
    setEndDate(null)
    setStartTime(null)
    setEndTime(null)
    setDateAndTimeError(null)

    setShowModal(false)
    if (callReloadScheduleCallback) {
      reloadScheduleCallback()
    }
  }

  function handleStreamChange(index, attribute) {
    let updatedStreams = streams.map((stream, i) =>
      index === i ? { ...stream, [attribute]: !stream[attribute] } : stream
    )
    
    if (
      attribute === STREAM_ATTRIBUTE &&
      updatedStreams[index][STREAM_ATTRIBUTE] &&
      siteInfo.allowrecordings
    ) {
      updatedStreams[index].record = true
    } else if (attribute === STREAM_ATTRIBUTE && !updatedStreams[index][STREAM_ATTRIBUTE]) {
      updatedStreams[index].record = false
      updatedStreams[index].recordingPrivate = false
    }

    setStreams(updatedStreams)
  }

  const buildSchedulingDetailsArea = () => (
    <div>
      <div style={{ marginTop: "10px" }}>
        <Radio
          style={{ marginRight: "10px" }}
          label="Single Event"
          name="radioGroup"
          checked={type === "single"}
          disabled={schedulingInfo.editType}
          onChange={(_, data) => data.checked && setType("single")}
        />
        <Radio
          label="Event Series"
          name="radioGroup"
          checked={type === "recurring"}
          disabled={schedulingInfo.editType}
          onChange={(_, data) => data.checked && setType("recurring")}
        />
      </div>
      {isRecurring() && (
        <div>
          <h5 style={{ marginTop: "20px" }}>Day Selection</h5>
          {daysToUseError}
          <Form.Field style={{ marginTop: "10px" }}>
            {DAYS_OF_WEEK.map((day, i) => (
              <Checkbox
                key={`${day}:${i}`}
                style={{ marginRight: "10px" }}
                label={day}
                control="input"
                type="checkbox"
                checked={daysToUse.includes(i)}
                onChange={(_, data) =>
                  setDaysToUse(
                    data.checked
                      ? [...daysToUse, i]
                      : daysToUse.filter((val) => val !== DAYS_OF_WEEK.indexOf(day))
                  )
                }
              />
            ))}
            <h5>Occurrence Selection</h5>
            <Dropdown
              style={{ width: "250px" }}
              selection
              value={occurrenceOfMonth}
              options={OCCURRENCE_OPTIONS}
              onChange={(_, data) => setOccurrenceOfMonth(data.value)}
            />
          </Form.Field>
        </div>
      )}
      <h5>Date/Time Selection</h5>
      {dateAndTimeError}
      <Grid columns="equal" style={{ marginLeft: "1px" }}>
        <Grid.Row style={{ marginTop: "3px" }}>
          <Grid.Column>
            <Form.Field>
              <Label>{getDateLabel()} Start Date</Label>
              <DateInput
                localization="us"
                iconPosition="left"
                dateFormat="MM-DD-YYYY"
                closable={true}
                clearable={true}
                minDate={new Date().toLocaleDateString()}
                value={startDate}
                onChange={(_, data) => {
                  setStartDate(data.value)
                  if (type === SINGLE_TYPE) {
                    setEndDate(data.value)
                  }
                }}
                hideMobileKeyboard={true}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field>
              <Label>{getDateLabel()} End Date</Label>
              <DateInput
                localization="us"
                iconPosition="left"
                dateFormat="MM-DD-YYYY"
                closable={true}
                clearable={true}
                minDate={new Date().toLocaleDateString()}
                value={endDate}
                onChange={(_, data) => setEndDate(data.value)}
                hideMobileKeyboard={true}
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ marginTop: "3px" }}>
          <Grid.Column>
            <Form.Field>
              <Label>Event Start Time</Label>
              <TimeInput
                localization="us"
                iconPosition="left"
                timeFormat="ampm"
                closable={true}
                clearable={true}
                value={startTime}
                onChange={(_, data) => setStartTime(data.value)}
                hideMobileKeyboard={true}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field>
              <Label>Event End Time</Label>
              <TimeInput
                localization="us"
                iconPosition="left"
                timeFormat="ampm"
                closable={true}
                clearable={true}
                value={endTime}
                onChange={(_, data) => setEndTime(data.value)}
                hideMobileKeyboard={true}
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )

  const buildStreamsTable = () => (
    <Table celled style={{ marginTop: "5px" }}>
      <Table.Body>
        {streams.map((stream, i) => (
          <Table.Row key={`${stream}:${i}`} className="streamScheduleTableRow">
            <Table.Cell>
              <b>{stream.streamName}</b>
              {stream.streamIsPrivate ? (
                <p style={{ fontSize: "10px", fontStyle: "italic" }}>Private Stream</p>
              ) : null}
            </Table.Cell>
            <Table.Cell>
              <Checkbox
                className="streamScheduleCheckbox"
                label="Stream"
                control="input"
                type="checkbox"
                checked={stream[STREAM_ATTRIBUTE]}
                onChange={() => handleStreamChange(i, STREAM_ATTRIBUTE)}
              />
            </Table.Cell>
            <Table.Cell>
              <Checkbox
                className="streamScheduleCheckbox"
                label="Record Stream"
                control="input"
                type="checkbox"
                checked={stream[RECORD_ATTRIBUTE]}
                disabled={!siteInfo.allowrecordings || !stream[STREAM_ATTRIBUTE]}
                onChange={() => handleStreamChange(i, RECORD_ATTRIBUTE)}
              />
            </Table.Cell>
            <Table.Cell>
              <Checkbox
                style={{ float: "left" }}
                className="streamScheduleCheckbox"
                label="Do Not Show Recording in Public Feed"
                control="input"
                type="checkbox"
                checked={stream[RECORDING_PRIVATE_ATTRIBUTE]}
                disabled={
                  stream.streamIsPrivate || !(stream[STREAM_ATTRIBUTE] && stream[RECORD_ATTRIBUTE])
                }
                onChange={() => handleStreamChange(i, RECORDING_PRIVATE_ATTRIBUTE)}
              />
              <InfoBubble
                style={{ float: "left" }}
                size={"small"}
                disabled={
                  stream.streamIsPrivate || !(stream[STREAM_ATTRIBUTE] && stream[RECORD_ATTRIBUTE])
                }
                message={
                  "Recording will not be listed on your parish's public MassLivestream page, but can be access via the admin dashboard and shared via permalink."
                }
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )

  const buildSchedulingArea = () => (
    <div style={{ marginTop: "25px" }}>
      {editID && (
        <h4 className="text-center">
          Editing&nbsp;
          <span className="greenFont">{editType === SET_TYPE ? SINGLE_TYPE : editType}</span> event.
        </h4>
      )}
      {hasPrivateStreamScheduled && (
        <div class="text-center">
          <i>Note</i>: Editing a recurring event series containing a private stream will <b>not</b>
          &nbsp; maintain the current private URL.
          <br />
          To maintain the current private URL, please edit as an individual event.
        </div>
      )}
      <Form style={{ marginTop: "25px" }}>
        <Form.Field>
          <Label>Event Title</Label> {titleError}
          <Input value={title} onChange={(_, data) => setTitle(data.value)} />
        </Form.Field>
        <Form.Field>
          <Checkbox
            style={{ float: "left" }}
            label={"Do not show in public schedule"}
            control="input"
            type="checkbox"
            value={makePrivate}
            onChange={(_, data) => setMakePrivate(data.checked)}
          />
          <InfoBubble
            style={{ float: "left" }}
            size={"small"}
            message={
              <div>
                <p>
                  Event will not be listed in the schedule on your parish's public MassLivestream
                  page, but the stream will still be displayed there during the event period.
                </p>
                <p>Only applicable to public streams.</p>
                <p>Selecting this option will skip webhook triggering for this event.</p>
              </div>
            }
          />
        </Form.Field>
        <p>&nbsp;</p>
        <h3>Stream Options</h3>
        {streamsError}
        {buildStreamsTable()}
        <h3>Schedule Details</h3>
        {buildSchedulingDetailsArea()}
      </Form>
    </div>
  )

  const buildModalContent = () =>
    schedulingInfoLoadError ? (
      <LoadingError />
    ) : !schedulingInfoLoaded ? (
      <Dimmer active>
        <Loader inline="centered" />
      </Dimmer>
    ) : (
      buildSchedulingArea()
    )

  return (
    <Modal
      style={{ marginTop: "45px" }}
      showModal={showModal}
      trigger={
        editType === RECURRING_TYPE ? (
          <Button className="blue">Series</Button>
        ) : editType ? (
          <Button className="blue">Event</Button>
        ) : (
          <Button className="blue">Schedule Event</Button>
        )
      }
      onClose={() => resetAndClose(false)}
      onOpen={() => setShowModal(true)}
      open={showModal}
      size={"large"}>
      <Header icon="edit" content={`Schedule an event`} />
      <Modal.Content>{buildModalContent()}</Modal.Content>
      <Modal.Actions>
        {scheduleResult}
        <Button disabled={scheduleInProgress} onClick={() => resetAndClose(false)}>
          Close
        </Button>
        <Button
          className="blue"
          disabled={scheduleInProgress}
          loading={scheduleInProgress}
          onClick={createEvent}>
          {editID ? "Update" : "Schedule"} Event
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
